import {Module} from "vuex";

export type SMSProvider = "smsru"

export interface ISMS {
    id: number
    company_id: number
    provider: SMSProvider
    key: string
}

const smsModule = <Module<any, any>>{
    namespaced: true,
    state: () => ({
        sms: <ISMS | undefined>undefined
    }),
    mutations: {},
    getters: {}
}

export default smsModule;