import {Module} from "vuex";

export interface ISMTP {
    id: number
    company_id: number
    host: string
    port: number
    username: string
    password: string
    from_address: string
    from_name: string
}

const smtpModule = <Module<any, any>>{
    namespaced: true,
    state: () => ({
        smtp: <ISMTP | undefined>undefined
    }),
    mutations: {

    },
    getters: {

    }
}

export default smtpModule;