<template>
  <v-card>
    <v-card-title>Источники клиентов</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-skeleton-loader
              v-if="loading"
              class="rounded-circle ml-4"
              max-width="200"
              type="image"
          ></v-skeleton-loader>
          <VueApexCharts v-else style="max-width: 300px" type="donut" :options="options" :series="series"/>
        </v-col>
        <v-col cols="12" md="8">
          <v-list-item v-for="source in data" :key="source.id">
            <v-list-item-content>
              <v-list-item-title>{{ source.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ source.customers_count }} клиентов</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "LeadSourcesStats",
  components: {VueApexCharts},
  props: ['sources'],
  data() {
    return {
      data: this.sources,
      loading: false,
     // colors: ["#ff4d4d", "#ffb24d", "#f3ff4d", "#59ff4d", "#4dffaf", "#4db5ff", "#654dff", "#ff4de1", "#ff4df6", "#ff4d6e",],
    }
  },
  computed: {
    options() {
      if (this.isNA) {
        return {
          labels: ["Нет клиентов"],
          colors: ["#8a8a8a"],
          legend: {
            position: "bottom",
          }
        }
      }
      return {
        labels: this.data.map(p => p.name),
     //   colors: this.colors,
        legend: {
          show: false,
        }
      }
    },
    series() {
      if (this.isNA) {
        return [1]
      }
      return this.data.map(p => p.customers_count);
    },
    isNA() {
      return this.data.filter(p => p.customers_count > 0).length === 0;
    }
  },
}
</script>

<style scoped>

</style>