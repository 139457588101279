import {Module} from "vuex";

export type DomainStatus = "pending" | "active" | "disabled";

export type DomainVerificationWay = "meta" | "txt" | "dns";

export interface IDomain {
    id: number
    company_id: number
    domain_name: string
    status: DomainStatus
    verification_way: DomainVerificationWay
    domain_secret: string
    meta_value: string
    txt_value: string
    dns_value: string
}

export interface IVerificationData {
    "way": DomainVerificationWay,
    "title": string,
    "key": string,
    "value": string,
}

export const VerificationWays: { key: DomainVerificationWay, value: string, description: string }[] = [
    {
        "key": "meta",
        "value": "meta-тэг на сайте",
        "description": "Если у вас есть сайт на выбранном домене, разместите в секции <head> тэг <meta> с указанными атрибутами",
    },
    {
        "key": "txt",
        "value": ".txt файл в корне сайта",
        "description": "Если у вас есть сайт на выбранном домене, разместите текстовый файл со следующим содержимым по указанному маршруту",
    },
    {
        "key": "dns",
        "value": "DNS запись (TXT)",
        "description": "Добавьте новую TXT запись в DNS записи вашего домена в вашем регистраторе",
    },
];

const domainModule = <Module<any, any>>{
    namespaced: true,
    state: () => ({
        domain: <IDomain | undefined>undefined
    }),
    mutations: {

    },
    getters: {

    }
}

export default domainModule;