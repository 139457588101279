import axios, {AxiosInstance, AxiosResponse, AxiosError} from 'axios';

export type ApiResponse = any;

interface ApiError {
    status: number
    body: any
}

const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_ENDPOINT}/api`,
    // timeout: 10000,
    headers: {
        'accept': 'application/json',
    }
});

// Настройка интерсепторов
instance.interceptors.response.use(
    function (response: AxiosResponse<any,ApiResponse>): any {
        // Обработка успешного ответа
        return response.data;
    },
    function (error: AxiosError): Promise<never> {
        // Обработка ошибки
        if (error.response && error.response.status === 401) {
            console.log("LOGOUT");
            localStorage.removeItem('access_token');
        }
        return Promise.reject({
            status: error.response ? error.response.status : 500,
            body: error.response ? error.response.data : 'Unknown error'
        });
    }
);

instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
    return config;
});

export default instance;