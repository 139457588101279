<template>
  <v-app>
    <v-navigation-drawer app v-if="showMenu" v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <v-img contain position="left" max-height="50" alt="logo"
                   :src="company?.settings?.logo ||require('@/assets/logo.png')"/>
          </v-list-item-title>
          <v-list-item-subtitle>
            CRM "МояФабрика" v.1.0.6
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/companies" v-if="!onCustomHost">
        <v-list-item-content>
          <v-list-item-title>
            {{ company?.settings?.brand_name || "Выберите организацию" }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-menu-right-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item link to="/auth/branches" v-if="availableBranchSelect">
        <v-list-item-content>
          <v-list-item-title>
            {{ branch?.name || "Выберите филиал" }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-menu-right-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>

      <v-list dense nav>
        <div v-for="item in visibleRoutes" :key="item.title">
          <v-list-item v-if="!item.children" link :to="item.to">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else>
            <template v-slot:activator>
              <v-list-item link class="pl-0">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item class="ml-3" :to="child.to" :key="child.title" v-for="(child,j) in item.children" link>
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Выйти</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="pb-16">
      <v-system-bar
          dark
          color="red lighten-2"
          v-if="showMenu && !company.subscription"
      >
        <v-icon>mdi-alert</v-icon>
        <span>Подписка истекла</span>
      </v-system-bar>
      <v-alert
          v-if="showMenu && !user.tg_channel_id"
          dense
          text
          color="primary"
      >
        <v-row align="center">
          <v-col class="grow">
            Подпишитесь на Telegram бота, чтобы оперативно получать все уведомления
          </v-col>
          <v-col class="shrink">
            <v-btn color="secondary" small @click="tgSubscribe()">Подписаться</v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-container fluid class="pb-16">
        <router-view/>
      </v-container>
    </v-main>
    <v-bottom-navigation app shift v-if="showMenu && isMobile">
      <v-btn to="/money" icon v-if="company && company.access_level===2">
        <span>Деньги</span>
        <v-icon>mdi-cash</v-icon>
      </v-btn>
      <v-btn to="/orders" icon>
        <span>Заказы</span>

        <v-icon>mdi-cart-outline</v-icon>
      </v-btn>

      <v-btn to="/" icon>
        <span>Главная</span>

        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>

      <v-btn to="/customers" icon v-if="company && company.access_level===1">
        <span>Клиенты</span>

        <v-icon>mdi-toolbox</v-icon>
      </v-btn>
      <v-btn @click="drawer=!drawer" icon>
        <span>Меню</span>

        <v-icon>mdi-menu</v-icon>
      </v-btn>

    </v-bottom-navigation>
  </v-app>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from "vuex";

export default Vue.extend({
  name: 'App',

  data() {
    return {
      bottom: null,
      drawer: !this.$vuetify.breakpoint.mobile,
    }
  },
  computed: {
    ...mapGetters(['jwt', 'routes', 'user', 'visibleRoutes', 'company', 'branch', 'availableBranchSelect','onCustomHost']),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    showMenu() {
      return this.jwt && this.user && this.user.company_context_id && this.$route.name !== "form";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
      this.$router.push('/login')
    },
    tgSubscribe() {
      window.open(`https://t.me/MyFabricCRMbot?start=user-${this.user.id}`, '_blank')
    }
  }
});
</script>
<style>
.income {
  background: #dfffae;
}

.expense {
  background: #ffaed0;
}

.expecting {
  background: #ebebeb;
}

.swal2-styled.swal2-confirm {
  background-color: #0D6B78 !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.woot-widget-bubble{
  bottom: 60px !important;
}
@media (min-width: 678px) {
  .woot-widget-bubble {
    bottom: 20px !important;
  }
}
</style>