import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
// @ts-ignore
import QueryHelper from "./mixins/QueryHelper.js";
import VueMask from 'v-mask';
import * as Sentry from "@sentry/vue";
import {Store} from "vuex";
import {AppState} from "@/store";

// @ts-ignore
Vue.use(VueMask)
Vue.config.productionTip = false
Vue.mixin(QueryHelper);
Vue.mixin({
  methods: {
    copyObject(object) {
      return JSON.parse(JSON.stringify(object))
    }
  }
})

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DNS,
  environment: process.env.VUE_APP_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')